import React, { useState, useEffect } from 'react'
import './App.css'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import SideDraw from './SideDraw'
import Modal from './Modal'
import Alert from './Alert'
import BMI from './BMI'
import EstHeight from './EstHeight'
import DripCalc from './DripCalc'
import ComingSoon from './ComingSoon'
import Help from './Help'
import About from './About'
import Install from './Install'
import BroadcastChannel from 'broadcast-channel'

const theme = createMuiTheme({
    palette: {
      primary: { main: '#005EB8' },
    },
    typography: {
        useNextVariants: true,
    },
    breakpoints: {
        values: {
            sm: 568
        }
    }
})

function App() {
    
    const [appState, setAppState] = useState({
        imperial: false,
        mobileOpen: false,
        page: 'BMI',
        showSWAlert: false,
        showUpdateAlert: false,
        alertMessage: '',
        showModal: false,
    })
    
    const swListener = new BroadcastChannel('swListener')
    const updateListener = new BroadcastChannel('updateListener')
    
    const handlePageChange = (event, page) => {
        setAppState({ ...appState, page, mobileOpen: false, showModal: false })
    }
    
    const handleDrawToggle = () => {
        setAppState({ 
            ...appState, 
            mobileOpen: !appState.mobileOpen,
            showModal: !appState.showModal,
        })
    }

    const handleCloseModal = () => {
        setAppState({ ...appState, showModal: false, mobileOpen: false })
    }
    
    const handleImperial = (event) => {
        setAppState({ ...appState, imperial: !appState.imperial})
        event.preventDefault()
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            document.activeElement.blur()
        }
    }
    
    const handleCloseAlert = () => {
        swListener.close()
        updateListener.close()
        return setAppState({ 
            ...appState, 
            showSWAlert: false,
            showUpdateAlert: false,
        })
    }
    
    useEffect(() => {
        document.addEventListener("keydown", handleEnterKey, false)
        
        swListener.onmessage = (e) => {
            setAppState({
                ...appState,
                showSWAlert: true,
                alertMessage: e,
            })
        }
        updateListener.onmessage = (e) => {
            setAppState({
                ...appState,
                showUpdateAlert: true,
                alertMessage: e,
            })
        }
    }, [])
    

    const renderSwitch = (page) => {
        switch(page) {
            case 'BMI': return (
                <BMI handleDrawToggle={handleDrawToggle}
                    imperial={appState.imperial}
                    handleImperial={handleImperial} />
            )
            case 'Ulna': return (
                <EstHeight 
                    handleDrawToggle={handleDrawToggle}
                    imperial={appState.imperial}
                    handleImperial={handleImperial} />
            )
            case 'Drip': return (
                <DripCalc handleDrawToggle={handleDrawToggle} />
            )
            case 'Help': return (
                <Help handleDrawToggle={handleDrawToggle} />
            )
            case 'Install App': return (
                <Install handleDrawToggle={handleDrawToggle} />
            )
            case 'About': return (
                <About handleDrawToggle={handleDrawToggle} />
            )
            default: return (
                <ComingSoon handleDrawToggle={handleDrawToggle} />
            )
        }
    }

    return (
        <Grid className="App">
        <Modal handleCloseModal={handleCloseModal} showModal={appState.showModal} />
        <MuiThemeProvider theme={theme}>
            <Alert 
                handleCloseAlert={handleCloseAlert} 
                showSWAlert={appState.showSWAlert}
                alertText={appState.alertMessage} />
            <Alert 
                handleCloseAlert={handleCloseAlert} 
                showUpdateAlert={appState.showUpdateAlert}
                alertText={appState.alertMessage}  />
            <SideDraw 
                toggle={appState.mobileOpen}
                handlePageChange={handlePageChange}
                handleDrawToggle={handleDrawToggle}/>
            {
                renderSwitch(appState.page)
            }
        </MuiThemeProvider>
        </Grid>
    )
}

export default App
