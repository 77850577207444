import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TopBar from './TopBar'

const styles = theme => ({
    content: {
        padding: '3% 5%',
        maxWidth: '500px',
        margin: '0 auto',
    },
    textBlocks: {
        paddingBottom: '15px'
    },
})

const Help = (props) => {
    const { classes } = props
    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            title='Help'/>
        <Grid container>
            <Grid item className={classes.content}>
                <Typography variant='h6' className={classes.textBlocks}>
                    BMI
                </Typography>
                <Typography variant='body1' className={classes.textBlocks}>
                    Select whether you wish to enter imperial or metric values at the top right. Enter the height and weight of the patient. The Body Mass Index will be displayed along with a graph showing safe and danger areas.
                </Typography>
                <Typography variant='h6' className={classes.textBlocks}>
                    Ulna
                </Typography>
                <Typography variant='body1' className={classes.textBlocks}>
                    Using the Ulna length, you can estimate a patients height. Select whether you wish to enter imperial or metric values at the top right. Select gender and age of patient. Enter your Ulna measurement. The estimated height will be displayed.
                </Typography>
                <Typography variant='h6'>
                    Drip
                </Typography>
                <Typography variant='body1' className={classes.textBlocks}>
                    To calculate your I.V. drip rate, first select hours or minutes, then fill the values of volume, time and drop factor, your drip rate will be displayed.
                </Typography>
            </Grid>
        </Grid>
        </>
    )
}

export default withStyles(styles)(Help)