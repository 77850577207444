import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import EstHeightCalc from './EstHeightCalc'
import TopBar from './TopBar'

const styles = theme => ({
    content: {
        paddingTop: '2%',
        height: '75vh',
        maxHeight: '800px',
        maxWidth: '500px',
        margin: '0 auto',
    },
    forms: {
        maxHeight: '120px',
        alignSelf: 'center',
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    abbr: {
        fontSize: '0.5em',
    },
    textFields: {
        maxWidth: '120px',
    },
    centerAlign: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    grow: {
        width: '100%',
    },
})

function EstimateHeight(props) {

    const [heightState, setHeightState] = useState({
        calcHeight: 187,
        inches: 12,
        cms: 30,
        estHeightGenderAge: 'a',
    })

    const handleEstHeightGenderAge = (genderAge) => {
        setHeightState({
            ...heightState,
            estHeightGenderAge: genderAge,
            calcHeight: EstHeightCalc(heightState.cms, genderAge)
        })
    }

    const { classes } = props

    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            imperial={props.imperial}
            handleImperial={props.handleImperial}
            title='Estimate Height'/>
        <Grid container className={classes.content}>
            <Grid item xs={12} sm={6} md={6} className={classes.forms}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Male</FormLabel>
                        <RadioGroup
                            aria-label="MaleAge"
                            name="gender1"
                            className={classes.group}
                            value={heightState.estHeightGenderAge}
                            onChange={(e, value) => handleEstHeightGenderAge(value)}>
                            <FormControlLabel value="a" control={<Radio />} label="Under 65" />
                            <FormControlLabel value="b" control={<Radio />} label="Over 64" />
                        </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Female</FormLabel>
                        <RadioGroup
                            aria-label="FemaleAge"
                            name="gender2"
                            className={classes.group}
                            value={heightState.estHeightGenderAge}
                            onChange={(e, value) => handleEstHeightGenderAge(value)}>
                            <FormControlLabel value="c" control={<Radio />} label="Under 65" />
                            <FormControlLabel value="d" control={<Radio />} label="Over 64" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={12} className={classes.forms}>
                {/* <Typography variant="h6" color="inherit" className={classes.grow}>
                    Ulna length
                </Typography> */}
                {props.imperial
                    ? <Grid item xs={12}>
                        <TextField
                            className={classes.textFields}
                            id="inches"
                            label="Ulna length"
                            type="number"
                            value={heightState.inches}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                            }}
                            onChange={(e) => {
                                const cms = Math.floor(e.target.value * 2.54 * 10) / 10
                                setHeightState({
                                    ...heightState,
                                    calcHeight: EstHeightCalc(cms, heightState.estHeightGenderAge),
                                    cms: cms,
                                    inches: e.target.value,
                                })
                            }}
                        />
                    </Grid>
                    : <Grid item xs={12}>
                        <TextField
                            className={classes.textFields}
                            id="cms"
                            label="Ulna length"
                            type="number"
                            value={heightState.cms}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            onChange={(e) => {
                                setHeightState({
                                    ...heightState,
                                    calcHeight: EstHeightCalc(e.target.value, heightState.estHeightGenderAge),
                                    cms: e.target.value,
                                    inches: (Math.floor(e.target.value / 2.54 * 10)) / 10,
                                })
                            }}/>
                    </Grid>
                }
            </Grid>
            <Grid container className={classes.centerAlign}>
                <Grid item xs={12} sm={6} md={12} >
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Estimated Height:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    {heightState.calcHeight > 0
                        ? <Typography variant="h2" color="inherit" className={classes.grow}>
                            {heightState.calcHeight}<abbr className={classes.abbr}>cm</abbr>
                        </Typography>
                        : <Typography variant="h6" color="inherit" className={classes.grow}>
                            {heightState.calcHeight}
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default withStyles(styles)(EstimateHeight)
