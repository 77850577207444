import React from 'react'
import Grid from '@material-ui/core/Grid'

const Modal = (props) => {
    const { showModal, handleCloseModal } = props
  
    return (
        <Grid 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.3)',
                display: showModal ? 'block' : 'none',
                zIndex: 3,
            }}
            onClick={handleCloseModal}>
        </Grid>
    )
}

export default Modal