import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
    snack: {
        padding: '0 0 2% 2%',
    },
    close: {
        padding: theme.spacing.unit / 2,
      },
})

const Offline = (props) => {
    const { classes, handleCloseAlert, showSWAlert, showUpdateAlert, alertText } = props
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            className={classes.snack}
            open={ showSWAlert || showUpdateAlert }
            autoHideDuration={showSWAlert ? 10000 : 10000000}
            onClose={ handleCloseAlert }
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{ alertText }</span>}
            action={[
                <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={ handleCloseAlert }
                >
                <CloseIcon />
                </IconButton>,
            ]}
        />
    )
}

export default withStyles(styles)(Offline)