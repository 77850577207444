import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import TopBar from './TopBar'
import BMIGraph from './BMIGraph'
import handleBMIInput from './handleBMIInput'

const styles = theme => ({
    center: {
        display: 'table',
        margin: '0 auto'
    },
    content: {
        paddingTop: '3%',
        paddingBottom: '85px',
        height: '75vh',
        maxHeight: '800px',
        maxWidth: '800px',
        margin: '0 auto',
    },
    textFields: {
        maxWidth: '120px',
        margin: '0 10px',
    },
    guage: {
        padding: '0 5%',
        marginTop: 'auto',
        position: 'fixed',
        bottom: '40px',
        height: '40px',
        width: '90%',
    },
})

const BMI = (props) => {

    const [bmiState, setBmiState] = useState({
        feet: 5,
        inches: 6,
        cms: 168,
        stone: 12,
        pounds: 3,
        kgs: 78,
        bmi: 27.6,
        chart: 67.5,
    })

    const { classes } = props

    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            imperial={props.imperial}
            handleImperial={props.handleImperial}
            title='BMI Calculator' />
        <Grid container className={classes.content}>
            <Grid  item xs={6} sm={6} md={12}>
                {/* <Typography variant="h6" color="inherit">
                    Height
                </Typography> */}
                {props.imperial
                    ? <Grid>
                        <TextField
                            className={classes.textFields}
                            id="feet"
                            label="Height"
                            type="number"
                            value={bmiState.feet}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'feet', bmiState, setBmiState)} />
                        <TextField
                            className={classes.textFields}
                            id="inches"
                            label=" "
                            type="number"
                            value={bmiState.inches}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'inches', bmiState, setBmiState)} />
                    </Grid>
                    : <Grid>
                        <TextField
                            className={classes.textFields}
                            id="cms"
                            label="Height"
                            type="number"
                            value={bmiState.cms}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'cms', bmiState, setBmiState)} />
                    </Grid>
                }
            </Grid>
            <Grid  item xs={6} sm={6} md={12}>
                {/* <Typography variant="h6" color="inherit">
                    Weight
                </Typography> */}
                {props.imperial
                    ? <Grid>
                        <TextField
                            className={classes.textFields}
                            id="stone"
                            label="Weight"
                            type="number"
                            value={bmiState.stone}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">st</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'stone', bmiState, setBmiState)} />
                        <TextField
                            className={classes.textFields}
                            id="pounds"
                            type="number"
                            label=" "
                            value={bmiState.pounds}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'pounds', bmiState, setBmiState)} />
                    </Grid>
                    : <Grid>
                        <TextField
                            className={classes.textFields}
                            id="kgs"
                            label="Weight"
                            type="number"
                            value={bmiState.kgs}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                            }}
                            onChange={(e) => handleBMIInput(e, 'kgs', bmiState, setBmiState)} />
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" color="inherit">
                    BMI: {bmiState.bmi}
                </Typography>
            </Grid>
            <BMIGraph chart={bmiState.chart}/>
        </Grid>
        </>
    )
}

export default withStyles(styles)(BMI)