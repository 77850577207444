export default (event, type, bmiState, setBmiState) => {
    if (event.target.value < 0) {
        return
    }

    const toFeetInches = (cms) => {
        const decimalFeet = cms / 30.48
        const feet = Math.floor(decimalFeet)
        const inches = (decimalFeet % 1) * 12
        return [feet, inches]
    }
    
    const toCms = (feet, inches) => {
        const feetToCms = feet * 30.48
        const inchesToCms = inches * 2.54
        return feetToCms + inchesToCms
    }
    
    const toStonePounds = (kgs) => {
        const decimalStone = kgs / 6.35029318
        const stone = Math.floor(decimalStone)
        const pounds = (decimalStone % 1) * 14
        return [stone, pounds]
    }
    
    const toKgs = (stone, pounds) => {
        const stoneToKgs = stone * 6.35029
        const poundsToKgs = pounds * 0.453592
        return stoneToKgs + poundsToKgs
    }
    
    let kgs = bmiState.kgs
    let bmi = bmiState.bmi
    let stone = bmiState.stone
    let pounds = bmiState.pounds
    let feet = bmiState.feet
    let inches = bmiState.inches
    let cms = bmiState.cms
    let chart = bmiState.chart
    
    switch(type) {
        case 'kgs': 
        kgs = event.target.value
        bmi = (event.target.value / Math.pow((bmiState.cms / 100) , 2)).toFixed(1)
        stone = toStonePounds(event.target.value)[0].toFixed(0)
        pounds = toStonePounds(event.target.value)[1].toFixed(0)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        case 'cms':
        cms = event.target.value
        bmi = (bmiState.kgs / Math.pow((event.target.value / 100), 2)).toFixed(1)
        feet = toFeetInches(event.target.value)[0].toFixed(0)
        inches = toFeetInches(event.target.value)[1].toFixed(0)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        case 'feet': 
        cms = toCms(event.target.value, bmiState.inches).toFixed()
        feet = event.target.value
        bmi = (bmiState.kgs / Math.pow((cms / 100), 2)).toFixed(1)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        case 'inches':
        cms = toCms(bmiState.feet, event.target.value).toFixed()
        inches = event.target.value
        bmi = (bmiState.kgs / Math.pow((cms / 100), 2)).toFixed(1)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        case 'stone':
        kgs = toKgs(event.target.value, bmiState.pounds).toFixed()
        stone = event.target.value
        bmi = (kgs / Math.pow((bmiState.cms / 100), 2)).toFixed(1)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        case 'pounds':
        kgs = toKgs(bmiState.stone, event.target.value).toFixed()
        pounds = event.target.value
        bmi = (kgs / Math.pow((bmiState.cms / 100), 2)).toFixed(1)
        chart = (Math.pow(bmi,3.7) / 100) - (Math.pow(bmi,3.7) / 103.25)
        break
        default: return
    }

    return setBmiState({ 
        ...bmiState,
        kgs,
        stone,
        pounds,
        cms,
        feet,
        inches,
        bmi,
        chart: chart > 98 ? 98 : chart
    })
    
}