import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'

const AppBarNoShadow = withStyles({
    root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        top: 'auto',
        bottom: 0,
        paddingBottom: 40,
        zIndex: 2,
    },
  })(AppBar)

const styles = {
    center: {
        display: 'table',
        margin: '0 auto'
    },
    arrowContainer: {
        height: '24px',
        paddingTop: '5px',
        position: 'relative',
        width: '90%',
        margin: '0 auto',
    },
    graph: {
        height: '18px',
        width: '90%',
        whiteSpace: 'nowrap',
        background: `linear-gradient(
            to right,
            rgb(238, 34, 35),
            rgb(238, 34, 35) 3%,
            rgb(253, 185, 19) 6%,
            rgb(253, 185, 19) 14%,
            rgb(120, 165, 14) 18%,
            rgb(120, 165, 14) 44%,
            rgb(253, 185, 19) 48%,
            rgb(253, 185, 19) 91%,
            rgb(237, 28, 36) 95%,
            rgb(237, 28, 36)
        )`,
        boxShadow: '0px 4px 5px 1px #ddd',
        position: 'relative',
        zIndex: 1,
        margin: '0 auto',
    },
    
    markers: {
        textAlign: 'left',
        position: 'relative',
        height: '100%',
        width: '90%',
        fontSize: '0.7em',
        margin: '0 auto',
    },
    marker1: {
        display: 'inline-block',
        position: 'absolute',
        left: '13.6%',
    },
    marker2: {
        display: 'inline-block',
        position: 'absolute',
        left: '46%'
    },
    marker3: {
        display: 'inline-block',
        position: 'absolute',
        left: '91%'
    }
}

const BMIGraph = (props) => (
    <AppBarNoShadow position="fixed" color="default">
        <div style={styles.arrowContainer}>
            <div style={{
                position: 'absolute',
                left: props.chart + '%',
                width: 0,
                height: 0,
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderTop: '18px solid #333',
            }}></div>
        </div>
        <div style={styles.graph}></div>
        <div style={styles.markers}>
            <div style={styles.marker1}>
                <div style={styles.center}>|</div>
                <div style={styles.center}>18.5</div>
            </div>
            <div style={styles.marker2}>
                <div style={styles.center}>|</div>
                <div style={styles.center}>25</div>
            </div>
            <div style={styles.marker3}>
                <div style={styles.center}>|</div>
                <div style={styles.center}>30</div>
            </div>
        </div>
    </AppBarNoShadow>
)

export default BMIGraph