import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TopBar from './TopBar'

const styles = theme => ({
    content: {
        padding: '3%',
        maxWidth: '500px',
        margin: '0 auto',
    },
    textBlocks: {
        paddingBottom: '15px'
    }
})

const About = (props) => {
    const { classes } = props
    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            title='About'/>
        <Grid container>
            <Grid item className={classes.content}>
                <Typography variant='body1' className={classes.textBlocks}>
                    Thank you for trying Nursing Tools. If you wish to contact me or have any questions, please visit 'my portfolio page'.
                </Typography>
                <Typography variant='body1' className={classes.textBlocks}>
                    This free progressive web app has been designed to help healthcare professionals perform regular calculations via a simple user interface.
                </Typography>
                <Typography variant='h6'>
                    The Smallprint
                </Typography>
                <Typography variant='body1' className={classes.textBlocks}>
                    Nursing tools has been designed for use by medical professionals, to assist with calculations and paperwork. It is not intended to replace clinical judgement in any way. This software does not warrant accuracy of the contained information or that it is free from errors.
                </Typography>
            </Grid>
        </Grid>
        </>
    )
}

export default withStyles(styles)(About)