import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TopBar from './TopBar'

const styles = theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
})

const Install = (props) => {
    const { classes } = props
    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            title='Install'/>
        <Grid container>
            <Grid item className={classes.content}>
                <Typography type='h4'>
                    PWA installation instructions based on browser
                </Typography>
            </Grid>
        </Grid>
        </>
    )
}

export default withStyles(styles)(Install)