import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'

const styles = {
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    paddingLeft: '3%',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  imperialButton: {
    width: 78,
    marginRight: -15,
  },
  emptyButton: {
    minWidth: '78px',
  }
}

function ButtonAppBar(props) {
  return (
    <div style={styles.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={props.handleDrawToggle}
            style={styles.menuButton}
            color="inherit"
            aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" style={styles.title}>
            {props.title}
          </Typography>
          {props.handleImperial
            ? <Button
                color="inherit"
                style={styles.imperialButton}
                onClick={props.handleImperial}>
                {props.imperial ? 'Imperial' : 'Metric'}
            </Button>
          : <Button className={styles.emptyButton}></Button> }
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default ButtonAppBar