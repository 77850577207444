import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import TopBar from './TopBar'

const styles = theme => ({
    content: {
        paddingTop: '2%',
        height: '75vh',
        maxHeight: '800px',
        maxWidth: '500px',
        margin: '0 auto',
    },
    forms: {
        alignSelf: 'center',
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    abbr: {
        fontSize: '0.5em',
    },
    textFields: {
        maxWidth: '120px',
    },
    centerAlign: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    grow: {
        width: '100%',
    },
    formControl: {
        marginBottom: 0,
    }
})

function DripCalc(props) {

    const [dripState, setDripState] = useState({
        dripRate: 1000,
        volume: 1000,
        dropFactor: 1,
        time: 1,
        timeType: 'hrs',
    })

    const handleTimeType = (timeType) => {
        setDripState({
            ...dripState,
            timeType: timeType,
            dripRate: calc(timeType, dripState.volume, dripState.time, dripState.dropFactor),
        })
    }

    const calc = (timeType, volume, time, dropFact) => {
        const hrs = timeType === 'hrs' ? time : time / 60
        return (volume * dropFact) / hrs
    }
        
    const { classes } = props

    return (
        <>
        <TopBar
            handleDrawToggle={props.handleDrawToggle}
            title='Drip Calculator'/>
        <Grid container className={classes.content}>
            <Grid item xs={12} sm={6} md={6} className={classes.forms}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup
                        aria-label="TimeType"
                        name="timeType"
                        className={classes.group}
                        value={dripState.timeType}
                        onChange={(e, value) => handleTimeType(value)}>
                        <FormControlLabel value="hrs" control={<Radio />} label="Hours" />
                        <FormControlLabel value="mins" control={<Radio />} label="Minutes" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={12} className={classes.forms}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textFields}
                        label="Volume"
                        id="volume"
                        type="number"
                        value={dripState.volume}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                        }}
                        onChange={(e) => {
                            setDripState({
                                ...dripState,
                                volume: e.target.value,
                                dripRate: calc(dripState.timeType, e.target.value, dripState.time, dripState.dropFactor),
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textFields}
                        label="Time"
                        id="time"
                        type="number"
                        value={dripState.time}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{dripState.timeType}</InputAdornment>,
                        }}
                        onChange={(e) => {
                            setDripState({
                                ...dripState,
                                time: e.target.value,
                                dripRate: calc(dripState.timeType, dripState.volume, e.target.value, dripState.dropFactor),
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textFields}
                        label="Drop Factor"
                        id="dropFactor"
                        type="number"
                        value={dripState.dropFactor}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">gtts/ml</InputAdornment>,
                        }}
                        onChange={(e) => {
                            setDripState({
                                ...dripState,
                                dropFactor: e.target.value,
                                dripRate: calc(dripState.timeType, dripState.volume, dripState.time, e.target.value),
                            })
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.centerAlign}>
                <Grid item xs={12} sm={6} md={12} >
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Drip Rate:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    {dripState.dripRate > 0
                        ? <Typography variant="h2" color="inherit" className={classes.grow}>
                            {dripState.dripRate}<abbr className={classes.abbr}>gtts/min</abbr>
                        </Typography>
                        : <Typography variant="h6" color="inherit" className={classes.grow}>
                            Out of range
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default withStyles(styles)(DripCalc)
