const estHeight = (measuredLength, sexAndAge) => {
    if (measuredLength < 18.5 || measuredLength > 32) {
        return 'Out of range'
    }
    const menUnder65_a = [1.94,1.93,1.91,1.89,1.87,1.85,1.84,1.82,1.8,1.78,1.76,1.75,1.73,1.71,1.69,1.67,1.66,1.64,1.62,1.60,1.58,1.57,1.55,1.53,1.51,1.49,1.48,1.46].reverse()
    const menOver64_b = [1.87,1.86,1.84,1.82,1.81,1.79,1.78,1.76,1.75,1.73,1.71,1.70,1.68,1.67,1.65,1.63,1.62,1.60,1.59,1.57,1.56,1.54,1.52,1.51,1.49,1.48,1.46,1.45].reverse()
    const womenUnder65_c = [1.84,1.83,1.81,1.80,1.79,1.77,1.76,1.75,1.73,1.72,1.70,1.69,1.68,1.66,1.65,1.63,1.62,1.61,1.59,1.58,1.56,1.55,1.54,1.52,1.51,1.5,1.48,1.47].reverse()
    const womenOver64_d = [1.84,1.83,1.81,1.79,1.78,1.76,1.75,1.73,1.71,1.70,1.68,1.66,1.65,1.63,1.61,1.60,1.58,1.56,1.55,1.53,1.52,1.50,1.48,1.47,1.45,1.44,1.42,1.40].reverse()
    
    const ulnaLengths = () => {
        let lengths = []
        for (let i = 18.5 ; i < 32.5 ; i += 0.5) {
            lengths.push(i)
        }
        return lengths
    }

    const rounded = Math.round(measuredLength * 2) / 2
    
    switch (sexAndAge) {
        case 'a': return menUnder65_a[ulnaLengths().indexOf(rounded)] * 100
        case 'b': return menOver64_b[ulnaLengths().indexOf(rounded)] * 100
        case 'c': return womenUnder65_c[ulnaLengths().indexOf(rounded)] * 100
        case 'd': return womenOver64_d[ulnaLengths().indexOf(rounded)] * 100
        default: return
    }
}


export default estHeight