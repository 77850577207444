import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { TapeMeasure, Heart, Water, Help, Information } from 'mdi-material-ui'
import logo from './Assets/android-icon-36x36.png'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 240

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: 'rgb(244, 245, 247)'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    logo: {
        marginRight: 10,
        width: '30px',
        marginLeft: 'auto',
    },
    menuTitle: {
        marginRight: 20,
    }
    })

function ResponsiveDrawer(props) {

    const [drawState, setDrawState] = useState({
        selectedIndex: 0,
    })

    const handleListItemClick = (event, index) => {
        setDrawState({
            ...drawState,
            selectedIndex: index
        })
    }

    const { classes, theme } = props

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={props.toggle}
                classes={{
                    paper: classes.drawerPaper,
                }}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={props.handleDrawToggle}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <img src={logo} alt="Logo" className={classes.logo} />
                <Typography variant="caption" color="inherit" className={classes.menuTitle}>nursing-tools.com</Typography>
            </div>
            <Divider />
            <List>
                {['BMI', 'Ulna', 'Drip'].map((text, index) => (
                <ListItem 
                    button key={text}
                    selected={drawState.selectedIndex === index}
                    onClick={event => {
                        props.handlePageChange(event, text)
                        handleListItemClick(event, index)
                    }}>
                    {[<Heart />, <TapeMeasure />, <Water />]
                        .filter((x, i) => i === index)
                        .map((x, i) => <ListItemIcon key={x + i}>{x}</ListItemIcon>)}
                    <ListItemText primary={text} />
                </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Help', 'About'].map((text, index) => (
                <ListItem 
                    button key={text}
                    selected={drawState.selectedIndex === index + 3}
                    onClick={event => {
                        props.handlePageChange(event, text)
                        handleListItemClick(event, index + 3)
                    }}>
                    {[<Help />, <Information />]
                        .filter((x, i) => i === index)
                        .map((x, i) => <ListItemIcon key={x + i}>{x}</ListItemIcon>)}
                    <ListItemText primary={text} />
                </ListItem>
                ))}
            </List>
            </Drawer>
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer)